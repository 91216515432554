export const useLoadingStore = defineStore("m-loading", {
  state: () => ({
    status: null,
  }),

  actions: {
    updateStatus(value) {
      this.status = value;
    },

    start() {
      this.updateStatus(true);
    },

    end() {
      this.updateStatus(false);
    },
  },
});
