<template>
  <div id="app">
    <l-main>
      <router-view :inert="inert" v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
    </l-main>
    <c-spinner v-if="status" key="spinner" />
    <w-modal />
  </div>
</template>

<script>
import { mapState } from "pinia";
import LMain from "@layouts/l-main";
import MBrowserSupport from "@modals/m-browser-support";
import DeviceDetector from "device-detector-js";
import WModal from "@/widgets/w-modal";
import CSpinner from "@/components/c-spinner";

export default {
  name: "App",
  components: { CSpinner, WModal, LMain },

  computed: {
    ...mapState(useLoadingStore, ["status"]),
    ...mapState(useModalStore, ["lastOpened"]),
    inert({ lastOpened }) {
      return Boolean(lastOpened);
    },
  },

  beforeCreate() {
    const urlPrefix = "identificacion";
    const isInternational = window.location.href.includes(urlPrefix);
    const productId = !isInternational ? "CTATRANS" : "DISTANCIA";
    const urlParams = new URLSearchParams(window.location.search);
    const valueOfV = urlParams.get("V");

    if (valueOfV === "1") {
      useAppStore().setVertical(1);
    } else if (valueOfV === "2") {
      useAppStore().setVertical(2);
    }

    const { documentTitle } = useAppStore();

    if (isInternational) {
      document.title = documentTitle;
    }
    useOnboardingStore().setProductId(productId);
  },

  mounted() {
    const device = new DeviceDetector().parse(navigator.userAgent);

    switch (true) {
      case device.os.name === "iOS":
        if (device.client.name !== "Safari" && device.client.name !== "Mobile Safari") {
          // Only Safari on iOS is supported
          useModalStore().open({
            component: MBrowserSupport,
            props: { safari: true },
          });
        }
        break;

      case device.client.name === "Internet Explorer":
      case device.client.name === "Microsoft Edge" && parseFloat(device.client.version) <= 76:
        // IE is not supported
        useModalStore().open({
          component: MBrowserSupport,
          props: { ie: true },
        });
        break;

      case device.client.name === "Mobile Safari" && parseFloat(device.client.version) > 11:
      case device.client.name === "Safari" && parseFloat(device.client.version) > 11:
      case device.client.name === "Microsoft Edge" && parseFloat(device.client.version) > 76:
      case device.client.name === "Chrome" && parseFloat(device.client.version) > 76:
      case device.client.name === "Chrome Mobile" && parseFloat(device.client.version) > 76:
      case device.client.name === "Firefox" && parseFloat(device.client.version) > 69:
      case device.client.name === "Firefox Mobile" && parseFloat(device.client.version) > 63:
      case device.client.name === "Samsung Browser" && parseFloat(device.client.version) > 11:
        // Supported :)
        break;

      default:
        // Not supported generic message
        useModalStore().open(MBrowserSupport);
    }
  },
};
</script>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
