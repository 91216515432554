const documentType = {
  DNI: 62,
  NIE: 188,
};
const VERTICAL_ERROR = "C404000254";
const COLECTIVE_ERROR = "C404000255";

export const useOnboardingStore = defineStore("m-onboarding", {
  state: () => ({
    id: "",
    step: 1,
    stepCount: 8,
    clientName: "",
    documentType: 0,
    productId: "CTATRANS",
    clientInformation: null,
    hasDoneIdentification: false,
    offices: null,
  }),

  actions: {
    setId(id) {
      this.id = id;
    },
    increaseStep(data) {
      this.step += data;
    },
    decreaseStep(data) {
      this.step -= data;
    },
    setName(name) {
      this.clientName = name;
    },
    setDocumentType(value) {
      this.documentType = value;
    },
    setProductId(value) {
      this.productId = value;
    },
    setClientInformation(value) {
      this.clientInformation = value;
      this.step = value.step;
      if (value.documentType === "DNI") {
        this.setDocumentType(documentType.DNI);
      } else {
        this.setDocumentType(documentType.NIE);
      }
    },
    sethasDoneIdentification(value) {
      this.hasDoneIdentification = value;
    },
    setOffices(offices) {
      this.offices = offices;
    },

    async createProcess(data) {
      try {
        await useAuthStore().login();
      } catch (err) {
        throw new Error(err);
      }

      useLoadingStore().start();

      const url = "/onboarding";
      const method = "POST";
      const initialData = {
        stepCount: this.stepCount,
        step: this.step,
        ...data,
      };

      return new Promise((resolve, reject) => {
        useServiceStore()
          .request({
            service: {
              request: {
                url,
                method,
              },
            },
            payload: initialData,
          })
          .then((res) => {
            this.setId(res.data.id);
            this.increaseStep(1);
            this.setName(initialData.name);
            useLoadingStore().end();

            resolve(res);
          })
          .catch((err) => {
            const { errorCode, additionalInfo } = err.response.data;
            const appStore = useAppStore();

            if ([VERTICAL_ERROR, COLECTIVE_ERROR].includes(errorCode)) {
              this.setId(additionalInfo.onboarding_id);
              this.increaseStep(1);
              this.setName(initialData.name);

              if (errorCode === VERTICAL_ERROR) {
                appStore.setVertical(null);
              }

              appStore.setCollective(null);
            }

            useLoadingStore().end();
            reject(err);
          });
      });
    },

    saveProcess(data) {
      useLoadingStore().start();

      const { id, step } = this;
      const initialData = { id, step, ...data };
      const url = `/onboarding/${id}`;
      const method = "PATCH";

      return new Promise((resolve, reject) => {
        useServiceStore()
          .request({
            service: {
              request: {
                url,
                method,
              },
            },
            payload: initialData,
          })
          .then((res) => {
            useLoadingStore().end();

            if (this.hasDoneIdentification && step === 5) {
              this.increaseStep(3);
            } else {
              this.increaseStep(1);
            }

            if (initialData?.idDocument?.type) {
              this.setDocumentType(documentType[initialData.idDocument.type]);
            }

            resolve(res);
          })
          .catch((err) => {
            useLoadingStore().end();
            reject(err);
          });
      });
    },

    async getPlaces(cod_postal) {
      const url = `/onboardings/places/${cod_postal}`;
      const method = "GET";

      return new Promise((resolve, reject) => {
        useServiceStore()
          .request({
            service: {
              request: {
                url,
                method,
              },
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getOffices(id) {
      const url = `/onboardings/${id}/offices`;
      const method = "GET";

      return new Promise((resolve, reject) => {
        useServiceStore()
          .request({
            service: {
              request: {
                url,
                method,
              },
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getOnBoarding(id) {
      const url = `/onboardings/${id}`;
      const method = "GET";

      return new Promise((resolve, reject) => {
        useServiceStore()
          .request({
            service: {
              request: {
                url,
                method,
              },
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getVerticals() {
      const url = `/onboardings/verticals`;
      const method = "GET";

      return new Promise((resolve, reject) => {
        useServiceStore()
          .request({
            service: {
              request: {
                url,
                method,
              },
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getCollectives(vertical) {
      const url = `/onboardings/collectives`;
      const method = "GET";

      return new Promise((resolve, reject) => {
        useServiceStore()
          .request({
            service: {
              request: {
                url,
                method,
              },
            },
            queryParams: {
              vertical,
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
