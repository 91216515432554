import { defineStore } from "pinia";
import { markRaw } from "vue";

export const useModalStore = defineStore("m-modal", {
  state: () => ({
    queue: {},
    queueProps: {},
    lastUID: 0,
  }),

  actions: {
    add(data) {
      this.lastUID += 1;
      this.queue[this.lastUID] = data;
    },
    props(data) {
      this.queueProps[this.lastUID] = data;
    },
    remove(id) {
      delete this.queue[id];
    },
    /**
     * Open a modal view.
     *
     * @param {Object} store
     * @param {Object} data
     * @param {Object} data.component A Modal Component class.
     * @param {Object} data.props Props to pass on a modal component instance.
     *
     * @example
     * ```js
     * 		await this.$store.dispatch('modal/open', {
     *				component: await import('@modals/m-sign-temp-blocked'),
     *				props: { minutes: 1000 },
     * 		});
     * ```
     *
     * @returns {Promise} Returns a promise which resolves when the modal closes.
     */
    open(data) {
      //const bugsnagStore = useBugsnagStore();

      return new Promise((resolve) => {
        const syncComponent = data.component ?? data;
        const modal = syncComponent.default
          ? markRaw(syncComponent.default)
          : markRaw(syncComponent);
        const props = data.component && data.props ? data.props : {};
        const Data = modal.render ? modal : modal;

        // const componentOptions =
        //   Data && Data.options ? { name: Data.options.name } : { text: data.text };
        // if (import.meta.env.MODE !== "test") {
        //   bugsnagStore.log({ title: `Modal open`, ...componentOptions });
        // }
        this.add(Data);
        this.props(props);

        const modalId = this.lastUID;

        const onRemove = (params) => {
          const { closedId, payload } = params;

          // eslint-disable-next-line eqeqeq
          if (closedId == modalId) {
            this.$emitter.off("remove", onRemove);
            resolve(payload);
          }
        };

        this.$emitter.on("remove", onRemove);

        if (modal.timeout !== Infinity && (modal.timeout || props.timeout || this.timeout)) {
          setTimeout(
            () => {
              this.close({ id: modalId });
            },
            modal.timeout || props.timeout || timeout,
          );
        }
      });
    },

    /**
     * Closes a modal.
     *
     * @param {Object} store
     * @param {Number} id Modal id to close
     */
    close({ id = this.lastUIDOpened, payload } = {}) {
      this.remove(id);
      this.$emitter.emit("remove", { closedId: id, payload });
    },

    /**
     * Replaces the current modal with another modal.
     *
     * @param {Object} store
     * @param {Object} modal Modal component
     */
    async replace(modal) {
      const id = this.lastUIDOpened;

      this.remove(id);
      await this.open(modal);

      this.$emitter.emit("remove", id);
    },
  },

  getters: {
    lastUIDOpened() {
      const [lastUID] = Object.keys(this.queue).slice(-1);
      return parseInt(lastUID, 10);
    },

    lastOpened() {
      return this.queue[this.lastUIDOpened];
    },

    lastOpenedProps() {
      return this.queueProps[this.lastUIDOpened];
    },
  },
});
