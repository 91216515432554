<template>
  <header class="c-header" :class="{ '--is-landing': isLanding }">
    <div class="c-header__container">
      <div class="c-header__logo" />
      <div v-if="!isLanding && productId === 'CTATRANS'" class="c-header__info"></div>
    </div>
  </header>
</template>

<script>
export default {
  name: "c-header",

  props: {
    isLanding: Boolean,
  },

  computed: {
    ...mapState(useOnboardingStore, ["productId"]),
  },
};
</script>
<style lang="scss" scoped>
.c-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 10px 0;
  z-index: 10;
}

.c-header__container {
  max-width: 1374px;
  max-height: 100px;
  min-height: 80px;
  margin-right: auto;
  margin-left: auto;
}

.c-header__container::before,
.c-header__container::after {
  content: " ";
  display: table;
}

.c-header.--is-landing .c-header__container {
  justify-content: space-between;
}

.c-header__logo {
  float: left;
  height: 100px;
  width: 280px;
  background-image: url(@project/assets/img/logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 16px;
}

.c-header.--is-landing .c-header__logo {
  width: 300px;
  padding: 10px;
  margin-left: 16px;
  background-image: url(@project/assets/img/logo.svg);
  background-origin: content-box;
}

.c-header__info {
  margin-top: 1rem;
  margin-right: 16px;
}

.c-header__info-desc1 {
  text-align: right;
  margin: 0;
}

.c-header__info-desc2 {
  color: $color-secondary !important;
  font-family: Poppins, sans-serif !important;
  font-weight: 600;
  font-size: 21px;
  text-align: right;
}

@media screen and (max-device-width: 480px) {
  .c-header {
    display: flex;
  }

  .c-header__container {
    height: 11%;
  }

  .c-header .c-header__container .c-header__logo,
  .c-header.--is-landing .c-header__container .c-header__logo {
    height: 80px;
  }

  .c-header__info-desc1 {
    font-size: 10px;
  }
}
</style>
