import { useModalStore } from "../modal/m-modal";

const UUID_EXPIRED = "C401000101";
const UUID_MISSED = "C4010000";

export const useErrorHandlerStore = defineStore("m-error-handler", {
  actions: {
    async handle({ error }) {
      if (error.code === "ECONNABORTED" || !error.response) {
        return error;
      }

      const { errorCode } = error.response.data;

      if (errorCode === UUID_EXPIRED || errorCode === UUID_MISSED) {
        useModalStore().open({
          component: await import("@modals/m-expired-session"),
        });
      }

      return error;
    },
  },
});
