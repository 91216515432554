export const useAuthStore = defineStore("m-authn", {
  actions: {
    async login() {
      await useSecureStore().createSession();
      useLoadingStore().start();

      const url = "/login";
      const method = "POST";

      const credentials = {
        companyId: useAppStore().companyId,
        channel: "WEB",
        deviceId: useDeviceStore().id,
      };

      return new Promise((resolve, reject) => {
        useServiceStore()
          .request({
            service: {
              request: {
                url,
                method,
              },
            },
            payload: credentials,
          })
          .then(({ data }) => resolve(data))
          .catch((err) => reject(err));
      });
    },
  },
});
