<template>
  <div class="l-main">
    <div class="l-main__container">
      <main>
        <slot v-if="isLanding" />
        <div v-else class="l-main__box">
          <c-header :isLanding="isLanding" />
          <div class="l-main__box-content">
            <slot />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import CHeader from "@components/c-header";

export default {
  name: "l-main",

  components: { CHeader },

  computed: {
    isLanding({ $route }) {
      return $route?.path === "/";
    },
  },
};
</script>

<style lang="scss" scoped>
.l-main {
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  background: white;
}

.l-main__container {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  background: #d3d3d3;
}

main {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.l-main__box {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1374px;
  justify-content: center;
}

.l-main__box-content {
  position: relative;
  height: 100%;
  margin-top: 160px;
  margin-bottom: 160px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 40px;
  background-color: white;
}

@media screen and (max-device-width: 480px) {
  .l-main__box,
  .l-main__container {
    background: #fff;
  }

  .l-main__box-content {
    margin-top: 120px;
    padding: 0;
  }

  .text-rps {
    font-size: 10px;
  }

  main {
    overflow-y: initial;
  }
}
</style>
