import { createRouter, createMemoryHistory } from "vue-router";
import routes from "@cbnk/router/routes";

const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
