const commonRoutes = [
  {
    path: "/:pathMatch(.*)*", // Cambiado para Vue Router 4
    redirect: { name: "app-landing" },
  },
  {
    path: "/",
    name: "app-landing",
    component: () => import("@steps/app-landing.vue"),
  },
  {
    path: "/app-gdpr-consent",
    name: "app-gdpr-consent",
    component: () => import("@/steps/app-gdpr-consent"),
  },
  {
    path: "/app-personal-data",
    name: "app-personal-data",
    component: () => import("@/steps/app-personal-data"),
  },
  {
    path: "/app-pending-onboarding",
    name: "app-pending-onboarding",
    component: () => import("@/steps/app-pending-onboarding"),
  },
  {
    path: "/app-choose-options",
    name: "app-choose-options",
    component: () => import("@/steps/app-choose-options"),
  },
  {
    path: "/app-address",
    name: "app-address",
    component: () => import("@/steps/app-address"),
  },
  {
    path: "/app-confirmation",
    name: "app-confirmation",
    component: () => import("@/steps/app-confirmation"),
  },
  {
    path: "/app-camera-capture",
    name: "app-camera-capture",
    component: () => import("@/steps/app-camera-capture"),
  },
  {
    path: "/app-election-office",
    name: "app-election-office",
    props: true,
    component: () => import("@/steps/app-election-office"),
  },
  {
    path: "/app-professional-data",
    name: "app-professional-data",
    component: () => import("@/steps/app-professional-data"),
  },
];

export default [...commonRoutes];
