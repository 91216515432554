import axios from "axios";
import { defineStore } from "pinia";

export const usePublicServiceStore = defineStore("m-public-service", {
  state: () => ({
    req: axios.create(),
    timeout: 20000,
  }),

  actions: {
    request(request) {
      const defaultHeaders = { "Content-Type": "application/json" };
      const headers = request.headers ? /* istanbul ignore next */ request.headers : defaultHeaders;
      const timeout = this.timeout;

      return this.req.request({
        headers,
        timeout,
        ...request,
      });
    },
  },
});
