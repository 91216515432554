<template>
  <transition name="fade">
    <section
      v-if="currentModal"
      class="w-modal"
      data-testid="modal"
      :class="{ '--fullscreen': currentProps.fullscreen }"
    >
      <div class="modal__overlay" tabindex="-1"></div>
      <div
        ref="modal"
        id="modal"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        role="dialog"
        @keydown.esc="closeModal"
        @keydown.tab="trapTabKey"
      >
        <div role="document" class="modal__document">
          <section class="modal-dialog modal__container" ref="container">
            <keep-alive>
              <component
                :is="currentModal"
                ref="externalComponent"
                v-bind="currentProps"
                id="modal-description"
                data-testid="modal"
                @close="closeModal"
              />
            </keep-alive>
          </section>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { useModalStore } from "@/store/modules/modal/m-modal";
import { mapState } from "pinia";

export default {
  name: "w-modal",

  computed: {
    ...mapState(useModalStore, {
      currentModal: (state) => state.lastOpened,
      currentProps: (state) => state.lastOpenedProps,
    }),
  },

  data() {
    return {
      title: null,
      observer: null,
      focusedElementBeforeModal: null,
      focusableElementsString: [
        "a[href]",
        "input:not([disabled])",
        "select:not([disabled])",
        "textarea:not([disabled])",
        "button:not([disabled])",
        "iframe",
        "object",
        "embed",
        '[tabindex]:not([tabindex="-1"])',
        "[contenteditable]",
      ].join(", "),
    };
  },

  methods: {
    /**
     * Closes current modal
     */
    closeModal() {
      useModalStore().close({ payload: this.$refs.externalComponent.value });
    },

    /**
     * Returns a list of focusable children elements
     */
    getFocusableItems() {
      return [...this.$el.querySelectorAll(this.focusableElementsString)];
    },

    trapTabKey(evt) {
      const focusableItems = this.getFocusableItems();
      const focusedItemIndex = focusableItems.indexOf(document.activeElement);

      const {
        0: firstElement,
        length: numberOfFocusableItems,
        [numberOfFocusableItems - 1]: lastElement,
      } = focusableItems;

      // if focused on first item and user preses back-tab, go to the last focusable item
      if (evt.shiftKey && focusedItemIndex === 0) {
        lastElement.focus();
        evt.preventDefault();

        // if focused on the last item and user preses tab, go to the first focusable item
      } else if (!evt.shiftKey && focusedItemIndex === numberOfFocusableItems - 1) {
        firstElement.focus();
        evt.preventDefault();
      }
    },

    setFocusToFirstItemInModal() {
      const nodes = this.getFocusableItems();

      if (nodes && nodes.length) {
        nodes[0].focus();
      }
    },
  },

  watch: {
    currentModal(currentModal) {
      if (currentModal) {
        if (!this.focusedElementBeforeModal) {
          this.focusedElementBeforeModal = document.activeElement;
        }

        this.$nextTick(() => {
          this.setFocusToFirstItemInModal.bind(this);
        });
      } else {
        // set focus back to element that had it before the modal was opened
        if (this.focusedElementBeforeModal) {
          this.focusedElementBeforeModal.focus();
        }

        this.focusedElementBeforeModal = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 166ms ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.w-modal {
  will-change: transform;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.w-modal.--fullscreen {
  padding: 0;
}

.modal__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba($color-secondary, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

#modal {
  background-color: white;
  position: relative;
  max-height: 100%;
  display: flex;
  border-radius: 0.3rem;
}

@media (min-width: 900px) {
  #modal {
    width: auto;
    height: auto;
  }
}

.modal__document {
  display: flex;
  overflow: auto;
  min-height: 100%;
  width: 100%;
  justify-content: center;
}

.modal__container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  padding: 0;
  margin: 0;
}

.modal__closebutton {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  height: 3rem;
  width: 3rem;
  padding: 0.5rem;
  background: transparent;
  border: 0;
  font-size: 2rem;
  font-weight: 100;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  font-family: sans-serif;
}
</style>
