import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import mitt from "mitt";
import PrimeVue from "primevue/config";
import "../../styles.scss";

const app = createApp(App);

const videoIdUrl =
  window?.VITE_APP_CONFIG?.videoIdUrl ||
  "https://etrust-sandbox.electronicid.eu/js/videoid-3.x/videoid.js";
const script = window.document.createElement("script");
script.type = "text/javascript";
script.src = videoIdUrl;

window.document.head.appendChild(script);

const pinia = createPinia();

const emitter = mitt();

pinia.use(({ store }) => {
  store.$emitter = emitter;
});

app.use(router);
app.use(pinia);
app.use(PrimeVue);

app.mount("#app");
