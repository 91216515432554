import { generateKeyPair, decryptRSA, encryptAES, decryptAES, getAESKey } from "./cypher";

export const useSecureStore = defineStore("m-secure", {
  /**
   * @typedef {Object} state
   * @property {Object} keyPair Crypto Key pair
   * @property {String} publicKey Base64 encoded public key
   * @property {Object} aesKey Crypto Symmetric Key
   * @property {String} seed Base64 encoded seed for AES
   * @property {String} symmetricKey Base64 encoded key for AES
   * @property {String} uuid Unique Session Id
   */
  state: () => ({
    aesKey: null,
    seed: null,
    symmetricKey: null,
    uuid: null,
    identity: null,
  }),

  actions: {
    setSessionData({ seed, symmetricKey, uuid }) {
      this.seed = seed;
      this.symmetricKey = symmetricKey;
      this.uuid = uuid;
      this.identity = Symbol(uuid);
      this.aesKey = null;
    },
    cleanSessionData() {
      this.seed = null;
      this.symmetricKey = null;
      this.uuid = null;
      this.identity = null;
      this.aesKey = null;
    },
    setAESKey(key) {
      this.aesKey = key;
    },
    /**
     * Create a new uuid session.
     */
    async createSession() {
      const { privateKey, publicKey } = await generateKeyPair();
      const url = "/key-exchange";
      const method = "POST";
      const baseURL = useServiceStore().baseURL;
      const headers = {
        "Content-Type": "application/json",
        "public-key": publicKey,
      };

      return new Promise((resolve, reject) => {
        usePublicServiceStore()
          .request({
            url,
            method,
            baseURL,
            headers,
          })
          .then(async ({ data }) => {
            if (data) {
              const response = await decryptRSA(privateKey, data);
              this.setSessionData(response);

              return resolve(response);
            }

            reject(data);
          })
          .catch(reject);
      });
    },

    /**
     * Remove current session.

     */
    removeSession() {
      this.cleanSessionData();
    },

    /**
     * Destroy and create a new uuid session.
     */
    refreshSession() {
      this.removeSession();

      return this.createSession();
    },

    /**
     * Returns the crypto AES Key.
     * @returns {Object} Crypto AES Key.
     */
    async getAESKey() {
      /* istanbul ignore next */
      if (!this.uuid) {
        await this.createSession();
      }

      const { symmetricKey, aesKey } = this;

      if (!aesKey) {
        const cryptoKey = await getAESKey(symmetricKey);

        this.setAESKey(cryptoKey);

        return cryptoKey;
      }

      return aesKey;
    },

    /**
     * Encrypt data with the symmetric key.
     * @param {Object} data Data to encrypt
     * @returns {String} A base64 encrypted data
     */
    async encrypt(data) {
      const key = await this.getAESKey();
      const { seed } = this;

      return encryptAES({
        seed,
        key,
        data,
      });
    },

    /**
     * Decrypt a base64 string with the symmetric key.
     * @param {String} data A base64 string data to decrypt.
     * @returns {Object} A clean payload.
     */
    async decrypt(data) {
      const key = await this.getAESKey();
      const { seed } = this;

      return decryptAES({
        seed,
        key,
        data,
      });
    },
  },
});
