import { defineStore } from "pinia";
export const useAppStore = defineStore("m-app", {
  state: () => ({
    companyId: "BC",
    companyName: "CBNK BANCO DE COLECTIVOS S.A.",
    isCBNK: true,
    isENG: false,
    isSAL: false,
    vertical: null,
    collective: null,
    officeSelected: false,
    informacionPrecontractual:
      "https://www.bancocaminos.es/documents/20486/217345/info_pre.pdf/49baae29-3ffd-430e-90ee-31ba31d9baa4",
    contratoMarco:
      "https://www.bancocaminos.es/documents/20486/246998/CONTRATO+MARCO+SERVICIOS+FINANCIEROS+BC.pdf/",
    telephone: "900 107 411",
    url: "https://www.cbnk.es",
    privacyPolicyURL: "https://www.cbnk.es/aviso-legal#politica-de-privacidad",
    documentTitle: "CBNK - Alta a distancia",
  }),

  actions: {
    setVertical(value) {
      this.vertical = value;
    },
    setCollective(value) {
      this.collective = value;
    },
    setOfficeSelected() {
      this.officeSelected = true;
    },
  },
});
